const imageData1 = {
    image:"2e64ae91a",
    id:"2e64ae91a",
    satellite:"Pleiades"
}

const imageData2 = {
    image:"2e189becf",
    id:"2e189becf",
    satellite:"Pleiades"
}

const imageData3 = {
    image:"2e521e0f7",
    id:"2e521e0f7",
    satellite:"Pleiades"
}

const imageData4 = {
    image:"2e0675f79",
    id:"2e0675f79",
    satellite:"Pleiades"
}

const imageData5 = {
    image:"2e1142a8c",
    id:"2e1142a8c",
    satellite:"Pleiades"
}

const imageData6 = {
    image:"03467751a",
    id:"03467751a",
    satellite:"Pleiades"
}

const imageData7 = {
    image:"005883911",
    id:"005883911",
    satellite:"Pleiades"
}

const imageData8 = {
    image:"007534159",
    id:"007534159",
    satellite:"Pleiades"
}

const imageData9 = {
    image:"014624873",
    id:"014624873",
    satellite:"Pleiades"
}

const imageData10 = {
    image:"024248678",
    id:"024248678",
    satellite:"Pleiades"
}


exports.imageData1 = imageData1
exports.imageData2 = imageData2
exports.imageData3 = imageData3
exports.imageData4 = imageData4
exports.imageData5 = imageData5
exports.imageData6 = imageData6
exports.imageData7 = imageData7
exports.imageData8 = imageData8
exports.imageData9 = imageData9
exports.imageData10 = imageData10